import * as React from 'react';

interface Props {
  url: string;
  text: string;
}

// used exclusively for spitting out the actual HTML that should
// be copied to the clipboard
export const escapedLinkForClipboard = (url, text): string => {
  return `<a href="${url}">${text}</a>`;
};

// used to render the HTML safely in the UI
const EscapedLink: React.FC<Props> = ({ text, url }) => {
  return (
    <code>
      &lt;a href=&quot;{url}&quot;&gt;
      {text}
      &lt;/a&gt;
    </code>
  );
};

export default EscapedLink;
