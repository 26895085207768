import * as React from 'react';
import { useCallback } from 'react';
import { Editor } from '@tiptap/react';
import {
  BoldIcon,
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  ItalicIcon,
  LinkIcon,
  ListOrderedIcon,
  ListUnorderedIcon,
  QuoteIcon,
  StrikeIcon,
  SubscriptIcon,
  SuperscriptIcon,
  VideoIcon,
} from './Icon';

interface ToolbarProps {
  editor: Editor;
}

const Toolbar: React.FC<ToolbarProps> = ({ editor }) => {
  const handleSetLink = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      const previousUrl = editor.getAttributes('link').href;
      const url = window.prompt('Enter the URL', previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
      }

      // update link
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    },
    [editor]
  );

  const handleSetYouTube = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      const url = prompt('Enter YouTube URL');

      // cancelled
      if (url === null) {
        return;
      }

      if (url) {
        editor.commands.setYoutubeVideo({
          src: url,
        });
      }
    },
    [editor]
  );

  if (!editor) {
    return null;
  }

  const handleToggleBold = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    editor.chain().focus().toggleBold().run();
  };

  const handleToggleItalic = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    editor.chain().focus().toggleItalic().run();
  };

  const handleToggleStrike = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    editor.chain().focus().toggleStrike().run();
  };

  const handleToggleOrderedList = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    e.preventDefault();
    editor.chain().focus().toggleOrderedList().run();
  };

  const handleToggleUnorderedList = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    e.preventDefault();
    editor.chain().focus().toggleBulletList().run();
  };

  const handleToggleHeading1 = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    e.preventDefault();
    editor.chain().focus().toggleHeading({ level: 1 }).run();
  };

  const handleToggleHeading2 = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    e.preventDefault();
    editor.chain().focus().toggleHeading({ level: 2 }).run();
  };

  const handleToggleHeading3 = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    e.preventDefault();
    editor.chain().focus().toggleHeading({ level: 3 }).run();
  };

  const handleToggleQuote = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    editor.chain().focus().toggleBlockquote().run();
  };

  const handleToggleSubscript = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    e.preventDefault();
    editor.chain().focus().toggleSubscript().run();
  };

  const handleToggleSuperscript = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    e.preventDefault();
    editor.chain().focus().toggleSuperscript().run();
  };

  return (
    <div className="text-editor-toolbar">
      <button
        className={editor.isActive('bold') ? 'is-active' : ''}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        onClick={handleToggleBold}
      >
        <BoldIcon size={24} />
      </button>
      <button
        className={editor.isActive('italic') ? 'is-active' : ''}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        onClick={handleToggleItalic}
      >
        <ItalicIcon size={24} />
      </button>
      <button
        className={editor.isActive('strike') ? 'is-active' : ''}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        onClick={handleToggleStrike}
      >
        <StrikeIcon size={24} />
      </button>
      <button
        className={editor.isActive('orderedList') ? 'is-active' : ''}
        onClick={handleToggleOrderedList}
      >
        <ListOrderedIcon size={24} />
      </button>
      <button
        className={editor.isActive('bulletList') ? 'is-active' : ''}
        onClick={handleToggleUnorderedList}
      >
        <ListUnorderedIcon size={24} />
      </button>
      <button
        className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
        onClick={handleToggleHeading1}
      >
        <Heading1Icon size={24} />
      </button>
      <button
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
        onClick={handleToggleHeading2}
      >
        <Heading2Icon size={24} />
      </button>
      <button
        className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
        onClick={handleToggleHeading3}
      >
        <Heading3Icon size={24} />
      </button>
      <button
        className={editor.isActive('blockquote') ? 'is-active' : ''}
        onClick={handleToggleQuote}
      >
        <QuoteIcon size={24} />
      </button>
      <button
        className={editor.isActive('link') ? 'is-active' : ''}
        onClick={handleSetLink}
      >
        <LinkIcon size={24} />
      </button>
      <button
        className={editor.isActive('youtube') ? 'is-active' : ''}
        onClick={handleSetYouTube}
      >
        <VideoIcon size={24} />
      </button>
      <button
        className={editor.isActive('subscript') ? 'is-active' : ''}
        onClick={handleToggleSubscript}
      >
        <SubscriptIcon size={24} />
      </button>
      <button
        className={editor.isActive('superscript') ? 'is-active' : ''}
        onClick={handleToggleSuperscript}
      >
        <SuperscriptIcon size={24} />
      </button>
    </div>
  );
};

export default Toolbar;
