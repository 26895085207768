import * as React from 'react';
import Audio from './Audio';

type LinkedAudio = {
  id: number;
  name: string;
  path: string;
  url: string;
  downloadUrl: string;
  caption: string;
};

interface AudiosProps {
  linkedAudios: LinkedAudio[];
}

const Audios: React.FC<AudiosProps> = ({ linkedAudios }) => {
  return (
    <ul>
      {linkedAudios.map((linkedAudio) => (
        <li key={linkedAudio.id}>
          <Audio
            audio={{
              name: linkedAudio.name,
              path: linkedAudio.path,
              downloadUrl: linkedAudio.downloadUrl,
              caption: linkedAudio.caption,
            }}
            url={linkedAudio.url}
          />
        </li>
      ))}
    </ul>
  );
};

export default Audios;
