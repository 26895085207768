import * as React from 'react';
import Image from './Image';

type LinkedImage = {
  id: number;
  name: string;
  path: string;
  url: string;
  caption: string;
};

interface ImagesProps {
  linkedImages: LinkedImage[];
}

const Images: React.FC<ImagesProps> = ({ linkedImages }) => {
  return (
    <ul className="flex flex-wrap items-start mt-2 justify-left">
      {linkedImages.map((linkedImage) => (
        <li key={linkedImage.id} className="w-1/2 p-1">
          <Image
            image={{
              name: linkedImage.name,
              path: linkedImage.path,
              caption: linkedImage.caption,
            }}
            url={linkedImage.url}
          />
        </li>
      ))}
    </ul>
  );
};

export default Images;
