import * as React from 'react';
import { useState } from 'react';
import Search, { SearchProps } from './Header/Search';
import Navigation, { NavLink } from './Header/Navigation';
import UserMenu, { UserProps } from './Header/UserMenu';

interface Props {
  search: SearchProps;
  navigation: {
    links: NavLink[];
  };
  user: UserProps;
}

const Header: React.FC<Props> = (props) => {
  const { search, navigation, user } = props;

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const handleOnSearchFocus = (): void => {
    setIsSearchFocused(true);
  };

  const handleOnSearchBlur = (): void => {
    setIsSearchFocused(false);
  };

  return (
    <nav className="bg-slate-800">
      <div className="relative block sm:flex items-center justify-between h-24">
        <div className="flex-1 pr-2 block sm:flex justify-center space-x-2">
          <Search
            isFocused={isSearchFocused}
            path={search.path}
            onFocus={handleOnSearchFocus}
            onBlur={handleOnSearchBlur}
          />
        </div>

        <div className="flex-none sm:flex justify-end space-x-2">
          <Navigation
            isSearchFocused={isSearchFocused}
            links={navigation.links}
          />
        </div>

        <div className="hidden sm:block flex-none">
          <UserMenu {...user} />
        </div>
      </div>
    </nav>
  );
};

export default Header;
