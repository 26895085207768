import * as React from 'react';
import Result from './Result';
import { Keyword } from '../../../../interfaces/models';
import { EntityResultsBaseProps } from '../EntityResults';

interface KeywordResultProps extends EntityResultsBaseProps<Keyword> {
  result: Keyword;
}

const KeywordResult: React.FC<KeywordResultProps> = (props) => {
  const { id, name } = props.result;

  return (
    <Result
      title={name}
      subTitle={`Keyword: ${id}`}
      entity={props.result}
      onAddEntity={props.onAddEntity}
      onClearResults={props.onClearResults}
    />
  );
};

export default KeywordResult;
