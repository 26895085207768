import * as React from 'react';
import { Fragment } from 'react';
import { Transition } from '@headlessui/react';

export interface NavLink {
  text: string;
  path: string;
  current: boolean;
}

interface Props {
  isSearchFocused: boolean;
  links: NavLink[];
}

const Navigation: React.FC<Props> = (props) => {
  const { isSearchFocused, links } = props;

  return (
    <Transition
      as={Fragment}
      show={!isSearchFocused}
      enter="transition ease-out duration-400"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div className="flex flex-wrap sm:flex-nowrap sm:pt-0 items-center justify-between sm:justify-normal sm:h-24">
        {links.map((link, i) => (
          <a
            key={i}
            href={link.path}
            className={`nav-link ${link.current ? 'mod-current' : ''}`}
          >
            {link.text}
          </a>
        ))}
      </div>
    </Transition>
  );
};

export default Navigation;
