import * as React from 'react';
import { Quiz } from '../../../../interfaces/models';
import { Entity } from '../EntityResults/Result';
import Table from '../../Table';
import { TrashIcon } from '@heroicons/react/24/outline';

interface RelatedQuizzesProps {
  quizzes: Quiz[];
  onRemove: (entity: Entity) => void;
}

const RelatedQuizzes: React.FC<RelatedQuizzesProps> = ({
  quizzes,
  onRemove,
}) => {
  const onRemoveQuiz = (
    e: React.MouseEvent<HTMLButtonElement>,
    quiz: Quiz
  ): void => {
    e.preventDefault();
    onRemove(quiz);
  };

  if (quizzes.length > 0) {
    return (
      <>
        <h3 className="medium-title mb-2 mt-3">Quizzes</h3>
        <Table headings={['ID', 'Name', 'Actions']}>
          {quizzes.map((quiz) => (
            <tr
              key={`${quiz.__typename}-${quiz.id}`}
              className="hover:bg-gray-50"
            >
              <td className="w-1 px-6 py-2 whitespace-nowrap">{quiz.id}</td>
              <td className="px-6 py-2 whitespace-nowrap">{quiz.name}</td>
              <td className="w-2 px-6 py-2 whitespace-nowrap">
                <button
                  className="btn py-1"
                  onClick={(e) => onRemoveQuiz(e, quiz)}
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
        </Table>
      </>
    );
  } else {
    return null;
  }
};

export default RelatedQuizzes;
