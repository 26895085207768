// responsible for fetching JSON data and throwing
// errors with relevant information if applicable
//
// Example:
//   fetchJSON(url)
//     .then(results => ...)
//     .catch(error => console.error(error.message))
//     .finally(...)
//
export const fetchJSON = async (url: string): Promise<object | []> => {
  const response = await fetch(url);

  if (!response.ok) {
    const text = await response.text();
    console.error(text);

    throw new Error(
      `A ${response.status} error occurred: ${response.statusText}`
    );
  }

  const json = await response.json();

  return json;
};
