import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { fetchJSON } from '../../../../utilities';
import { Asset } from '../../../../interfaces/models';
import { Tab } from '@headlessui/react';
import {
  LinkIcon,
  SpeakerWaveIcon,
  VideoCameraIcon,
  DocumentTextIcon,
  PhotoIcon,
} from '@heroicons/react/24/outline';
import Loading from '../../../Loading';
import EntitySelect from '../EntitySelect';
import Modal from '../../../Shared/Modal';
import EscapedLink, { escapedLinkForClipboard } from '../EscapedLink';
import Clipboard from '../../Clipboard';

enum AssetType {
  Image = 0,
  Video = 1,
  Audio = 2,
  Document = 3,
}

const tabClassNames = (active: boolean): string => {
  const css = active
    ? 'text-blue-500 border-blue-500'
    : 'text-gray-400 hover:text-blue-400 hover:border-blue-400 hover:bg-gray-50';

  return `${css} w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm focus:outline-none`;
};

const AssetLookup: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [buttonText, setButtonText] = useState<string>();
  const [asset, setAsset] = useState<Asset>();
  const [assetId, setAssetId] = useState<string>();
  const [assetsUrl, setAssetsUrl] = useState<string>();
  const [assetUrl, setAssetUrl] = useState<string>();
  const [selectPlaceholder, setSelectPlaceholder] = useState<string>();
  const [assets, setAssets] = useState<Asset[]>([]);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [loadingAsset, setLoadingAsset] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [copyUrl, setCopyUrl] = useState<string>();

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setAssetId(e.target.value);
  };

  const handleAssetLookup = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    if (!assetId) return;

    setLoadingAsset(true);

    fetchJSON(assetUrl)
      .then((result) => {
        setAsset(result as Asset);
        setIsOpen(true);
      })
      .catch((error) => {
        console.error('Asset lookup error: ', error.message);
      })
      .finally(() => setLoadingAsset(false));
  };

  useEffect(() => {
    setAssetId(null);

    switch (tabIndex) {
      case AssetType.Image:
        setButtonText('Get Image Link');
        setAssetsUrl('/admin/images.json');
        setSelectPlaceholder('-- Choose Image --');
        break;
      case AssetType.Video:
        setButtonText('Get Video Link');
        setAssetsUrl('/admin/videos.json');
        setSelectPlaceholder('-- Choose Video --');
        break;
      case AssetType.Audio:
        setButtonText('Get Audio Link');
        setAssetsUrl('/admin/audios.json');
        setSelectPlaceholder('-- Choose Audio --');
        break;
      case AssetType.Document:
        setButtonText('Get Document Link');
        setAssetsUrl('/admin/documents.json');
        setSelectPlaceholder('-- Choose Document --');
        break;
      default:
        setButtonText('Get Image Link');
        setAssetsUrl('/admin/images.json');
        setSelectPlaceholder('-- Choose Image --');
    }
  }, [tabIndex]);

  useEffect(() => {
    switch (tabIndex) {
      case AssetType.Image:
        setAssetUrl(`/admin/images/${assetId}.json`);
        setCopyUrl(`/images/${assetId}`);
        break;
      case AssetType.Video:
        setAssetUrl(`/admin/videos/${assetId}.json`);
        setCopyUrl(`/videos/${assetId}`);
        break;
      case AssetType.Audio:
        setAssetUrl(`/admin/audios/${assetId}.json`);
        setCopyUrl(`/audios/${assetId}`);
        break;
      case AssetType.Document:
        setAssetUrl(`/admin/documents/${assetId}.json`);
        setCopyUrl(`/documents/${assetId}`);
        break;
      default:
        setAssetUrl(`/admin/images/${assetId}.json`);
        setCopyUrl(`/images/${assetId}`);
    }
  }, [assetId, tabIndex]);

  // load the select dropdowns
  useEffect(() => {
    if (!assetsUrl) return;

    setLoadingAssets(true);

    fetchJSON(assetsUrl)
      .then((results) => {
        setAssets(results as Asset[]);
      })
      .catch((error) => {
        console.error('Assets load error: ', error.message);
      })
      .finally(() => setLoadingAssets(false));
  }, [assetsUrl]);

  return (
    <>
      <h2 className="body-heading-title">
        Asset Lookup
        {(loadingAssets || loadingAsset) && (
          <Loading className="h-4 w-4 absolute top-1 right-0" />
        )}
      </h2>
      <p className="text-sm mb-4">
        Use this utility to help find Asset links to embed in your custom HTML
        pages. Click the icon below for the Asset type you want to lookup.
      </p>

      <Tab.Group
        as={Fragment}
        defaultIndex={0}
        onChange={(index) => setTabIndex(index)}
      >
        <Tab.List className="-mb-px flex">
          <Tab className={({ selected }) => tabClassNames(selected)}>
            <PhotoIcon className="mx-auto h-6 w-6" />
          </Tab>
          <Tab className={({ selected }) => tabClassNames(selected)}>
            <VideoCameraIcon className="mx-auto h-6 w-6" />
          </Tab>
          <Tab className={({ selected }) => tabClassNames(selected)}>
            <SpeakerWaveIcon className="mx-auto h-6 w-6" />
          </Tab>
          <Tab className={({ selected }) => tabClassNames(selected)}>
            <DocumentTextIcon className="mx-auto h-6 w-6" />
          </Tab>
        </Tab.List>

        <div className="h-10 mt-4">
          {!loadingAssets && (
            <EntitySelect
              entities={assets}
              defaultText={selectPlaceholder}
              onSelectChange={handleSelectChange}
            />
          )}
        </div>

        <button
          className="btn-primary w-full mt-4 ml-0 inline-flex items-center space-x-2"
          onClick={handleAssetLookup}
        >
          <LinkIcon className="h-6 w-6" />
          <span>{buttonText}</span>
        </button>
      </Tab.Group>

      {asset && (
        <Modal title={asset.name} open={isOpen} setOpen={setIsOpen}>
          <div className="flex">
            <div className="flex-auto w-3/5">
              <p className="text-sm">
                In order to link to the <strong>{asset.name}</strong> image,
                copy the following HTML and paste it into your Page.
              </p>
            </div>
            <div className="flex-auto text-right">
              <Clipboard text={escapedLinkForClipboard(copyUrl, asset.name)} />
            </div>
          </div>

          <div className="mt-4 px-4 py-6 bg-gray-700 text-gray-100 text-sm overflow-x-auto whitespace-nowrap">
            <EscapedLink url={copyUrl} text={asset.name} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default AssetLookup;
