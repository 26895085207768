import * as React from 'react';
import { useState } from 'react';
import Modal from '../Shared/Modal';
import SwitchToggle, { LabelPosition } from '../Shared/SwitchToggle';

interface Props {
  exactMatchDefault: boolean;
}

const SearchExactToggle: React.FC<Props> = ({ exactMatchDefault }) => {
  const [helpOpen, setHelpOpen] = useState(false);
  const [exactMatch, setExactMatch] = useState(exactMatchDefault);

  const handleOnHelpClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();

    setHelpOpen(true);
  };

  return (
    <>
      <div className="flex items-center">
        <div>
          <SwitchToggle
            label="Match my search terms exactly"
            labelPosition={LabelPosition.RIGHT}
            enabled={exactMatch}
            setEnabled={setExactMatch}
          />
          <input type="hidden" name="exact" value={exactMatch ? 1 : 0} />
        </div>
        <div className="pl-1">
          <span
            onClick={handleOnHelpClick}
            className="text-gray-300 hover:text-gray-600 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-7 h-7"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
      </div>
      <Modal
        title="What does an exact match search mean?"
        open={helpOpen}
        setOpen={setHelpOpen}
      >
        <div className="text-sm space-y-4">
          <p>
            An exact match search is a case insensitive search that matches your
            phrase exactly as typed. For example, a search for "west virginia
            university" will only return results where the content matches on
            "west virginia university". Use this type of search when the order
            of the terms matter, and/or you know exactly what you're looking
            for.
          </p>
          <p>
            Considering the same search for "west virginia university", but one
            that is NOT an exact match, e-WV will search on "west", "virginia",
            and "university" separately. This type of search will likely have
            more results and turn up more related content, but won't consider
            the order of your terms.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default SearchExactToggle;
