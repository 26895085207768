import * as React from 'react';
import { EntityType } from './EntitySelect';
import {
  ArticleResult,
  AudioResult,
  AuthorResult,
  DocumentResult,
  ImageResult,
  KeyDateResult,
  KeywordResult,
  QuizResult,
  SlideshowResult,
  VideoResult,
} from './EntityResults/index';
import { Entity } from './EntityResults/Result';

export interface EntityResultsBaseProps<T> {
  onAddEntity: (entity: T) => void;
  onClearResults: () => void;
}

interface EntityResultsProps<T> extends EntityResultsBaseProps<T> {
  category: EntityType;
  results: T[];
}

function determineResultComponent(
  entityType: EntityType
): typeof React.Component {
  let Component;

  switch (entityType) {
    case EntityType.Article:
      Component = ArticleResult;
      break;
    case EntityType.Audio:
      Component = AudioResult;
      break;
    case EntityType.Author:
      Component = AuthorResult;
      break;
    case EntityType.Document:
      Component = DocumentResult;
      break;
    case EntityType.Image:
      Component = ImageResult;
      break;
    case EntityType.KeyDate:
      Component = KeyDateResult;
      break;
    case EntityType.Keyword:
      Component = KeywordResult;
      break;
    case EntityType.Quiz:
      Component = QuizResult;
      break;
    case EntityType.Slideshow:
      Component = SlideshowResult;
      break;
    case EntityType.Video:
      Component = VideoResult;
      break;
    default:
      Component = ArticleResult;
  }

  return Component;
}

const EntityResults: React.FC<EntityResultsProps<Entity>> = (props) => {
  const { category, results, onAddEntity, onClearResults } = props;

  const EntityResult = determineResultComponent(category);

  if (results.length > 0) {
    return (
      <div className="absolute w-full">
        <div className="bg-slate-800 shadow overflow-hidden rounded-b-lg">
          <ul
            role="list"
            className="border-t border-t-slate-600 divide-y divide-slate-600"
          >
            {results.map((result) => (
              <li key={result.id}>
                <EntityResult
                  result={result}
                  onAddEntity={onAddEntity}
                  onClearResults={onClearResults}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default EntityResults;
