import * as React from 'react';
import { useState } from 'react';
import Modal from '../Modal';
import { SpeakerWaveIcon } from '@heroicons/react/24/outline';
import AudioPlayer from '../../Application/AudioPlayer';

export type Audio = {
  name: string;
  path: string;
  downloadUrl: string;
  caption: string;
};

interface AudioProps {
  audio: Audio;
  url: string;
}

const Audio: React.FC<AudioProps> = ({ audio, url }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleAudioClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();

    setIsOpen(true);
  };

  return (
    <>
      <a className="related-media-link" onClick={handleAudioClick} href={url}>
        <span className="pr-2">
          <SpeakerWaveIcon className="h-8 w-8" strokeWidth={1} />
        </span>
        <span className="flex-1">{audio.name}</span>
        <span>
          <div className="pill">audio</div>
        </span>
      </a>

      <Modal title={audio.name} open={isOpen} setOpen={setIsOpen}>
        <div className="space-y-4 text-center">
          <AudioPlayer url={audio.path} downloadUrl={audio.downloadUrl} />
          <div
            className="px-4"
            dangerouslySetInnerHTML={{ __html: audio.caption }}
          />
          <p className="px-4">
            <a className="block btn py-3" href={url}>
              More Details &rarr;
            </a>
          </p>
        </div>
      </Modal>
    </>
  );
};

export default Audio;
