import * as React from 'react';
import { useState } from 'react';
import Modal from '../Shared/Modal';
import { KeyDate } from '../../interfaces/models';

interface Props {
  dates: KeyDate[];
}

const KeyDates: React.FC<Props> = ({ dates }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenDatesClick = (
    e: React.MouseEvent<HTMLAnchorElement>
  ): void => {
    e.preventDefault();

    setIsOpen(true);
  };

  return (
    <>
      <div>
        <a
          href="#"
          onClick={handleOpenDatesClick}
          className="generic-button mod-full mt-2"
        >
          <span className="flex-1 text-left">Key Dates</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
            />
          </svg>
        </a>
      </div>
      <Modal title="Key Dates in History" open={isOpen} setOpen={setIsOpen}>
        <div>
          {dates.map((date) => (
            <div className="flex" key={date.id}>
              <div className="align-middle w-1/4 border-r border-gray-700 py-6">
                <div className="border-b border-gray-700 mt-3 relative">
                  <span className="absolute bg-white -mt-3 pr-2">
                    {date.date}
                  </span>
                </div>
              </div>
              <div
                className="flex-1 py-6 pl-6"
                dangerouslySetInnerHTML={{ __html: date.notes }}
              ></div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default KeyDates;
