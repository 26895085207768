import * as React from 'react';
import { useEffect, useState } from 'react';
import { fetchJSON } from '../../../../utilities';
import { Page } from '../../../../interfaces/models';
import EntitySelect from '../EntitySelect';
import Modal from '../../../Shared/Modal';
import Loading from '../../../Loading';
import EscapedLink, { escapedLinkForClipboard } from '../EscapedLink';
import Clipboard from '../../Clipboard';
import { LinkIcon } from '@heroicons/react/24/outline';

interface PageLookupProps {
  htmlMode: boolean;
}

const PageLookup: React.FC<PageLookupProps> = ({ htmlMode }) => {
  const [pages, setPages] = useState<Page[]>([]);
  const [selectedPageId, setSelectedPageId] = useState<string>();
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingPages, setLoadingPages] = useState(false);
  const [page, setPage] = useState<Page>();
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setSelectedPageId(e.target.value);
  };

  const handlePageLookup = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    if (!selectedPageId) return;

    setLoadingPage(true);

    fetchJSON(`/admin/pages/${selectedPageId}.json`)
      .then((result) => {
        setPage(result as Page);
        setIsOpen(true);
      })
      .catch((error) => {
        console.error('Page lookup error: ', error.message);
      })
      .finally(() => setLoadingPage(false));
  };

  // load Pages dynmically on first load
  useEffect(() => {
    setLoadingPages(true);

    fetchJSON(`/admin/pages.json`)
      .then((results) => {
        setPages(results as Page[]);
      })
      .catch((error) => {
        console.error('Page load error: ', error.message);
      })
      .finally(() => setLoadingPages(false));
  }, []);

  return (
    <>
      <h2 className="body-heading-title">
        Page Lookup
        {loadingPage && <Loading className="h-4 w-4 absolute top-1 right-0" />}
      </h2>
      <p className="text-sm mb-4">
        Use this utility to lookup a Page and get a link to embed in your
        content.
      </p>

      {!loadingPages && (
        <EntitySelect
          entities={pages}
          defaultText="-- Choose Page --"
          onSelectChange={handleSelectChange}
        />
      )}

      <button
        className="btn-primary w-full mt-4 ml-0 inline-flex items-center space-x-2"
        onClick={handlePageLookup}
      >
        <LinkIcon className="h-6 w-6" />
        <span>Get Page Link</span>
      </button>

      {page && (
        <Modal title={page.title} open={isOpen} setOpen={setIsOpen}>
          <div className="flex">
            <div className="flex-auto w-3/5">
              <p className="text-sm">
                In order to link to the <strong>{page.title}</strong> page, copy
                the following {htmlMode && 'HTML and paste it into your Page.'}
                {!htmlMode &&
                  'URL and create a link using the text editor in your content.'}
              </p>
            </div>
            <div className="flex-auto text-right">
              {htmlMode && (
                <Clipboard
                  text={escapedLinkForClipboard(
                    `/pages/${page.permalink}`,
                    page.title
                  )}
                />
              )}
              {!htmlMode && <Clipboard text={`/pages/${page.permalink}`} />}
            </div>
          </div>

          <div className="mt-4 px-4 py-6 bg-gray-700 text-gray-100 text-sm overflow-x-auto whitespace-nowrap">
            {htmlMode && (
              <EscapedLink url={`/pages/${page.permalink}`} text={page.title} />
            )}
            {!htmlMode && `/pages/${page.permalink}`}
          </div>
        </Modal>
      )}
    </>
  );
};

export default PageLookup;
