// responsible for posting JSON data and throwing
// errors with relevant information if applicable
//
// Example:
//   postJSON(url)
//     .then(results => ...)
//     .catch(error => console.error(error.message))
//     .finally(...)
//

interface KeyValuePair {
  [key: string]: number | string;
}

interface FetchBody {
  [key: string]: number | string | KeyValuePair;
}

export const postJSON = async (
  url: string,
  body: FetchBody
): Promise<object | []> => {
  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content'),
    },
    method: 'POST',
    body: JSON.stringify(body || {}),
  });

  if (!response.ok) {
    const text = await response.text();
    console.error(text);

    throw new Error(
      `A ${response.status} error occurred: ${response.statusText}`
    );
  }

  const json = await response.json();

  return json;
};
