import * as React from 'react';

export enum EntityType {
  Article = 'Article',
  Audio = 'Audio',
  Author = 'Author',
  Document = 'Document',
  Image = 'Image',
  KeyDate = 'KeyDate',
  Keyword = 'Keyword',
  Quiz = 'Quiz',
  Slideshow = 'Slideshow',
  Video = 'Video',
}

export interface EntityMap {
  typename: EntityType;
  url: string;
}

interface EntitySelectProps {
  entityMap: EntityMap[];
  selectedIndex: number;
  onSelectChange: (value: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface EntitySelectOptionProps {
  name: EntityType;
  value: string;
}

const EntitySelectOption: React.FC<EntitySelectOptionProps> = (props) => {
  const { name, value } = props;

  return <option value={value}>{name}</option>;
};

const EntitySelect: React.FC<EntitySelectProps> = (props) => {
  const { entityMap, selectedIndex, onSelectChange } = props;
  const defaultValue = entityMap[selectedIndex].url;

  return (
    <select
      name="category"
      id="category"
      className="field-select related-select"
      onChange={onSelectChange}
      defaultValue={defaultValue}
    >
      {entityMap.map((entity, index) => (
        <EntitySelectOption
          key={index}
          name={entity.typename}
          value={entity.url}
        />
      ))}
    </select>
  );
};

export default EntitySelect;
