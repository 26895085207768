import * as React from 'react';
import { useEffect, useState } from 'react';

interface CarouselTickerProps {
  currentIndex: number;
  size: number;
  summarized: boolean;
  onClick: (tick: number) => void;
}

// builds a [1, 2, 3, 4, ...] array based on size
function generateArrayOfSize(length: number): number[] {
  const array = Array.from(Array(length + 1).keys());

  array.shift();

  return array;
}

const CarouselTicker: React.FC<CarouselTickerProps> = ({
  currentIndex,
  size,
  summarized,
  onClick,
}) => {
  const [currentTick, setCurrentTick] = useState(1);
  const ticker = generateArrayOfSize(size);

  useEffect(() => {
    setCurrentTick(currentIndex + 1);
  }, [currentIndex]);

  if (summarized) {
    return (
      <div>
        <div className="carousel-tick-summarized">
          {currentTick} of {size}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        {ticker.map((tick, index) => (
          <button
            key={index}
            className={`carousel-tick ${
              currentTick === tick ? 'active-tick' : ''
            }`}
            onClick={() => onClick(tick)}
          >
            {tick}
          </button>
        ))}
      </div>
    );
  }
};

export default CarouselTicker;
