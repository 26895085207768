import * as React from 'react';
import Result from './Result';
import { Author } from '../../../../interfaces/models';
import { EntityResultsBaseProps } from '../EntityResults';

interface AuthorResultProps extends EntityResultsBaseProps<Author> {
  result: Author;
}

const AuthorResult: React.FC<AuthorResultProps> = (props) => {
  const { id, first_name, last_name } = props.result;

  const name = [first_name, last_name].join(' ');

  return (
    <Result
      title={name}
      subTitle={`Author: ${id}`}
      entity={props.result}
      onAddEntity={props.onAddEntity}
      onClearResults={props.onClearResults}
    />
  );
};

export default AuthorResult;
