import * as React from 'react';
import Result from './Result';
import { Slideshow } from '../../../../interfaces/models';
import { EntityResultsBaseProps } from '../EntityResults';

interface SlideshowResultProps extends EntityResultsBaseProps<Slideshow> {
  result: Slideshow;
}

const SlideshowResult: React.FC<SlideshowResultProps> = (props) => {
  const { id, name } = props.result;

  return (
    <Result
      title={name}
      subTitle={`Slideshow: ${id}`}
      entity={props.result}
      onAddEntity={props.onAddEntity}
      onClearResults={props.onClearResults}
    />
  );
};

export default SlideshowResult;
