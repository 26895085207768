import * as React from 'react';
import { useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import Link from '@tiptap/extension-link';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import StarterKit from '@tiptap/starter-kit';
import Youtube from '@tiptap/extension-youtube';
import TextEditorToolbar from './TextEditor/Toolbar';

interface TextEditorProps {
  initialValue: string;
  label: string;
  postAs: string;
  css: string;
}

const TextEditor: React.FC<TextEditorProps> = ({
  initialValue,
  label,
  postAs,
  css,
}) => {
  const [content, setContent] = useState(initialValue || '');

  const cssClass = ['text-editor', css].join(' ');

  const editor = useEditor({
    content: content,
    extensions: [
      Link.configure({
        HTMLAttributes: {
          // Remove target entirely so links open in current tab
          target: null,
        },
        openOnClick: false,
      }),
      StarterKit,
      Subscript,
      Superscript,
      Youtube.configure({
        controls: false,
        modestBranding: true,
      }),
    ],
    editorProps: {
      attributes: {
        class: cssClass,
      },
    },
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setContent(html);
    },
  });

  return (
    <div className={cssClass}>
      <label className="label">{label}</label>

      <TextEditorToolbar editor={editor} />
      <EditorContent editor={editor} />

      <input type="hidden" name={postAs} value={content} />
    </div>
  );
};

export default TextEditor;
