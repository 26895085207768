import * as React from 'react';
import { Author } from '../../../../interfaces/models';
import { Entity } from '../EntityResults/Result';
import Table from '../../Table';
import { TrashIcon } from '@heroicons/react/24/outline';

interface RelatedAuthorsProps {
  authors: Author[];
  onRemove: (entity: Entity) => void;
}

const RelatedAuthors: React.FC<RelatedAuthorsProps> = ({
  authors,
  onRemove,
}) => {
  const onRemoveAuthor = (
    e: React.MouseEvent<HTMLButtonElement>,
    author: Author
  ): void => {
    e.preventDefault();
    onRemove(author);
  };

  if (authors.length > 0) {
    return (
      <>
        <h3 className="medium-title mb-2 mt-3">Authors</h3>
        <Table headings={['ID', 'Title', 'Actions']}>
          {authors.map((author) => (
            <tr
              key={`${author.__typename}-${author.id}`}
              className="hover:bg-gray-50"
            >
              <td className="w-1 px-6 py-2 whitespace-nowrap">{author.id}</td>
              <td className="px-6 py-2 whitespace-nowrap">
                {author.first_name} {author.last_name}
              </td>
              <td className="w-2 px-6 py-2 whitespace-nowrap">
                <button
                  className="btn py-1"
                  onClick={(e) => onRemoveAuthor(e, author)}
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
        </Table>
      </>
    );
  } else {
    return null;
  }
};

export default RelatedAuthors;
