import * as React from 'react';
import { useState } from 'react';
import Modal from '../Shared/Modal';

interface Props {
  content: string;
}

const Sidebar: React.FC<Props> = ({ content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenSidebarClick = (
    e: React.MouseEvent<HTMLAnchorElement>
  ): void => {
    e.preventDefault();

    setIsOpen(true);
  };

  return (
    <>
      <div>
        <a
          href="#"
          onClick={handleOpenSidebarClick}
          className="generic-button mod-full mt-2"
        >
          <span className="flex-1 text-left">Sidebar</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
            />
          </svg>
        </a>
      </div>
      <Modal title="Sidebar" open={isOpen} setOpen={setIsOpen}>
        <div
          className="space-y-4"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </Modal>
    </>
  );
};

export default Sidebar;
