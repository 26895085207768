import * as React from 'react';
import { Audio } from '../../../../interfaces/models';
import { Entity } from '../EntityResults/Result';
import Table from '../../Table';
import { TrashIcon } from '@heroicons/react/24/outline';

interface RelatedAudiosProps {
  audios: Audio[];
  onRemove: (entity: Entity) => void;
}

const RelatedAudios: React.FC<RelatedAudiosProps> = ({ audios, onRemove }) => {
  const onRemoveAudio = (
    e: React.MouseEvent<HTMLButtonElement>,
    audio: Audio
  ): void => {
    e.preventDefault();
    onRemove(audio);
  };

  if (audios.length > 0) {
    return (
      <>
        <h3 className="medium-title mb-2 mt-3">Audio Files</h3>
        <Table headings={['ID', 'Name', 'Actions']}>
          {audios.map((audio) => (
            <tr
              key={`${audio.__typename}-${audio.id}`}
              className="hover:bg-gray-50"
            >
              <td className="w-1 px-6 py-2 whitespace-nowrap">{audio.id}</td>
              <td className="px-6 py-2 whitespace-nowrap">{audio.name}</td>
              <td className="w-2 px-6 py-2 whitespace-nowrap">
                <button
                  className="btn py-1"
                  onClick={(e) => onRemoveAudio(e, audio)}
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
        </Table>
      </>
    );
  } else {
    return null;
  }
};

export default RelatedAudios;
