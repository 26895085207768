import * as React from 'react';
import { useRef } from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';

interface EntitySearchInputProps {
  query: string;
  onClearResults: () => void;
  onQueryChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const MIN_CHARACTER_INPUT = 3;

const EntitySearchInput: React.FC<EntitySearchInputProps> = ({
  query,
  onClearResults,
  onQueryChange,
}) => {
  const inputRef = useRef<HTMLInputElement>();

  function handleClearResults(e: React.MouseEvent<HTMLButtonElement>): void {
    e.preventDefault();
    onClearResults();
    inputRef.current.focus();
  }

  return (
    <div className="relative">
      <input
        type="text"
        ref={inputRef}
        value={query}
        onChange={onQueryChange}
        placeholder="Start typing your search..."
        className="field related-field"
      />
      {query.length > 0 && (
        <button
          onClick={handleClearResults}
          className="absolute top-1 right-1 mt-1 text-slate-400 hover:text-white"
        >
          <XCircleIcon className="h-7 w-7" />
        </button>
      )}
    </div>
  );
};

export default EntitySearchInput;
