import * as React from 'react';
import {
  Article,
  Audio,
  Author,
  Document,
  Image,
  KeyDate,
  Keyword,
  Quiz,
  Slideshow,
  Video,
} from '../../../../interfaces/models';
import { EntityResultsBaseProps } from '../EntityResults';

export type Entity =
  | Article
  | Audio
  | Author
  | Document
  | Image
  | KeyDate
  | Keyword
  | Quiz
  | Slideshow
  | Video;

interface ResultProps<T> extends EntityResultsBaseProps<T> {
  title: string;
  subTitle: string;
  entity: T;
}

const Result: React.FC<ResultProps<Entity>> = (props) => {
  const { entity, subTitle, title, onAddEntity, onClearResults } = props;

  const handleAddEntity = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();

    onAddEntity(entity);
    onClearResults();
  };

  return (
    <div className="px-6 py-4 hover:bg-slate-700">
      <div className="flex items-center justify-between">
        <div className="text-sm truncate text-ellipsis mr-8">
          <div
            className="font-medium text-white"
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>
          {subTitle && <p className="text-slate-400">{subTitle}</p>}
        </div>
        <div className="shrink-0 flex">
          <a href="/" onClick={handleAddEntity} className="btn border-0">
            Add
          </a>
        </div>
      </div>
    </div>
  );
};

export default Result;
