import * as React from 'react';
import { useState } from 'react';
import { Answer } from '../../../interfaces/models';
import { TrashIcon } from '@heroicons/react/24/outline';

interface Props {
  index: number;
  answer: Answer;
}

const QuestionAnswer: React.FC<Props> = (props) => {
  const { index, answer } = props;
  const isEditing = answer.id;

  const [isDestroy, setIsDestroy] = useState(false);

  const handleDestroy = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setIsDestroy(true);
  };

  return (
    <>
      {isEditing && (
        <input
          type="hidden"
          name={`question[answers_attributes][${index}][id]`}
          defaultValue={answer.id}
        />
      )}
      {isEditing && isDestroy && (
        <input
          type="hidden"
          name={`question[answers_attributes][${index}][_destroy]`}
          value="1"
        />
      )}

      {!isDestroy && (
        <>
          <div className="col-span-6">
            <label className="label">Answer Text</label>
            <input
              type="text"
              name={`question[answers_attributes][${index}][name]`}
              defaultValue={answer.name}
              className="field"
            />
          </div>
          <div className="col-span-2 mt-8">
            <label className="label">
              <input
                name={`question[answers_attributes][${index}][correct]`}
                type="hidden"
                value="0"
              />
              <input
                type="checkbox"
                value="1"
                name={`question[answers_attributes][${index}][correct]`}
                className="cb-field"
                defaultChecked={answer.correct}
              />
              <span className="inline-block ml-2">Correct?</span>
            </label>
          </div>
          <div className="col-span-1 text-right mt-6">
            <button className="btn" onClick={handleDestroy}>
              <TrashIcon className="h-5 w-5" />
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default QuestionAnswer;
