import * as React from 'react';
import Result from './Result';
import { Document } from '../../../../interfaces/models';
import { EntityResultsBaseProps } from '../EntityResults';

interface DocumentResultProps extends EntityResultsBaseProps<Document> {
  result: Document;
}

const DocumentResult: React.FC<DocumentResultProps> = (props) => {
  const { id, name } = props.result;

  return (
    <Result
      title={name}
      subTitle={`Document: ${id}`}
      entity={props.result}
      onAddEntity={props.onAddEntity}
      onClearResults={props.onClearResults}
    />
  );
};

export default DocumentResult;
