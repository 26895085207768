import * as React from 'react';

interface TableProps {
  headings: string[];
}

const Table: React.FC<TableProps> = ({ headings, children }) => {
  return (
    <div className="flex flex-col">
      <div className="-my-1 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-1 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {headings.map((heading, index) => (
                    <th key={index} scope="col" className="table-th">
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {children}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
