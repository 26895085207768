import * as React from 'react';
import Result from './Result';
import { Quiz } from '../../../../interfaces/models';
import { EntityResultsBaseProps } from '../EntityResults';

interface QuizResultProps extends EntityResultsBaseProps<Quiz> {
  result: Quiz;
}

const QuizResult: React.FC<QuizResultProps> = (props) => {
  const { id, name } = props.result;

  return (
    <Result
      title={name}
      subTitle={`Quiz: ${id}`}
      entity={props.result}
      onAddEntity={props.onAddEntity}
      onClearResults={props.onClearResults}
    />
  );
};

export default QuizResult;
