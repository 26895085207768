import * as React from 'react';
import Modal from '../../Shared/Modal';

interface Props {
  isOpen: boolean;
  title: string;
  message: string;
  onComplete: () => void;
}

const SaveConfirmation: React.FC<Props> = ({
  isOpen,
  title,
  message,
  onComplete,
}) => {
  const handleComplete = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    onComplete();
  };

  return (
    <Modal title={title} open={isOpen} setOpen={() => {}}>
      <div className="space-y-6 text-center">
        <p>{message}</p>
        <div>
          <button
            className="btn flex items-center space-x-2 mx-auto"
            onClick={handleComplete}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </span>
            <span>Got it!</span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SaveConfirmation;
