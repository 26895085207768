import * as React from 'react';
import { useState } from 'react';
import Modal from '../../Shared/Modal';
import { Collection } from '../../../interfaces/models';
import { postJSON } from '../../../utilities';

interface Props {
  linkText: string;
  onCreate: (collection: Collection) => void;
}

const NewCollection: React.FC<Props> = ({ linkText, onCreate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState('');

  const handleNewClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();

    setIsOpen(true);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setNewCollectionName(e.target.value);
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    postJSON('/collections.json', { name: newCollectionName })
      .then((result) => {
        const collection = result as Collection;
        onCreate(collection);
      })
      .catch((error) => {
        console.error('Error creating new Collection: ', error.message);
      })
      .finally(() => {
        setIsOpen(false);
      });
  };

  return (
    <>
      <a href="/" className="block sm:inline-block mt-4 sm:mt-0" onClick={handleNewClick}>
        {linkText}
      </a>
      <Modal title="Create a New Collection" open={isOpen} setOpen={setIsOpen}>
        <div className="flex items-center space-x-3">
          <div className="flex-1">
            <input
              type="text"
              className="field p-3"
              placeholder="What do you want to call your new Collection?"
              onChange={handleNameChange}
            />
          </div>
          <div>
            <button className="btn" onClick={handleSubmit}>
              Save Collection
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewCollection;
