import * as React from 'react';
import { useState } from 'react';
import {
  BeakerIcon,
  BookOpenIcon,
  CalendarIcon,
  CameraIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClipboardDocumentListIcon,
  RectangleStackIcon,
  EllipsisHorizontalCircleIcon,
  FolderIcon,
  HomeIcon,
  NewspaperIcon,
  PaperClipIcon,
  TagIcon,
  RectangleGroupIcon,
  UserGroupIcon,
  UserCircleIcon,
  Squares2X2Icon,
} from '@heroicons/react/24/outline';

interface NavLink {
  text: string;
  path: string;
  icon: string;
  current?: string;
}

interface PrimaryNavLink extends NavLink {
  hasSubNav?: boolean;
}

interface NavigationProps {
  links: PrimaryNavLink[];
  subLinks: NavLink[];
}

const IconMap = (iconKey: string): React.ReactNode => {
  const css = 'h-6 w-6 inline-block ml-0 mr-3';

  switch (iconKey) {
    case 'articles':
      return <NewspaperIcon className={css} />;
    case 'assets':
      return <PaperClipIcon className={css} />;
    case 'authors':
      return <UserCircleIcon className={css} />;
    case 'categories':
      return <FolderIcon className={css} />;
    case 'classroom':
      return <BookOpenIcon className={css} />;
    case 'dashboard':
      return <Squares2X2Icon className={css} />;
    case 'dates':
      return <CalendarIcon className={css} />;
    case 'goback':
      return <ChevronLeftIcon className={css} />;
    case 'home':
      return <HomeIcon className={css} />;
    case 'homepage':
      return <RectangleGroupIcon className={css} />;
    case 'keywords':
      return <TagIcon className={css} />;
    case 'more':
      return <EllipsisHorizontalCircleIcon className={css} />;
    case 'playground':
      return <BeakerIcon className={css} />;
    case 'photographers':
      return <CameraIcon className={css} />;
    case 'quizzes':
      return <ClipboardDocumentListIcon className={css} />;
    case 'slideshows':
      return <RectangleStackIcon className={css} />;
    case 'users':
      return <UserGroupIcon className={css} />;
    default:
      return null;
  }
};

const Link: React.FC<NavLink> = (props) => {
  const css = props.current ? 'bg-gray-900' : '';

  return (
    <a
      href={props.path}
      className={`${css} text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md`}
    >
      {IconMap(props.icon)}
      {props.text}
    </a>
  );
};

const SidebarNavigation: React.FC<NavigationProps> = (props) => {
  const { links, subLinks } = props;
  const shouldShowSubNav = subLinks.some((link) => link.current);

  const [showSubNav, setShowSubNav] = useState(shouldShowSubNav);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    setShowSubNav(!showSubNav);
  };

  return (
    <nav className="flex-1 px-2 pt-4 bg-gray-800 space-y-1 border-t-2 border-gray-700">
      {showSubNav && (
        <a
          onClick={handleClick}
          href="/admin/more"
          className="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        >
          {IconMap('goback')}
          Main Menu
        </a>
      )}
      {showSubNav &&
        subLinks.map((link, i) => {
          return (
            <Link
              key={i}
              text={link.text}
              path={link.path}
              current={link.current}
              icon={link.icon}
            />
          );
        })}
      {!showSubNav &&
        links.map((link, i) => {
          if (link.hasSubNav) {
            return (
              <a
                key={i}
                onClick={handleClick}
                href={link.path}
                className="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
              >
                <div className="w-full flex justify-between">
                  <div>
                    {IconMap(link.icon)}
                    {link.text}
                  </div>
                  <div className="self-end">
                    <ChevronRightIcon className="h-6 w-6 inline-block -ml-0" />
                  </div>
                </div>
              </a>
            );
          } else {
            return (
              <Link
                key={i}
                text={link.text}
                path={link.path}
                current={link.current}
                icon={link.icon}
              />
            );
          }
        })}
    </nav>
  );
};

export default SidebarNavigation;
