import * as React from 'react';
import { useState } from 'react';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import Modal from '../Modal';

export type Document = {
  name: string;
  path: string;
  caption: string;
};

interface DocumentProps {
  document: Document;
  url: string;
}

const Document: React.FC<DocumentProps> = ({ document, url }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDocumentClick = (
    e: React.MouseEvent<HTMLAnchorElement>
  ): void => {
    e.preventDefault();

    setIsOpen(true);
  };

  return (
    <>
      <a
        className="related-media-link"
        onClick={handleDocumentClick}
        href={url}
      >
        <span className="pr-2">
          <DocumentTextIcon className="h-8 w-8" strokeWidth={1} />
        </span>
        <span className="flex-1">{document.name}</span>
        <span>
          <div className="pill">pdf</div>
        </span>
      </a>

      <Modal title={document.name} open={isOpen} setOpen={setIsOpen}>
        <div className="space-y-4 text-center">
          <img
            className="block rounded modal-image mx-auto"
            src={document.path}
            alt={document.name}
          />
          <div
            className="px-4"
            dangerouslySetInnerHTML={{ __html: document.caption }}
          />
          <p className="px-4">
            <a className="block btn py-3" href={url}>
              Download + More Details &rarr;
            </a>
          </p>
        </div>
      </Modal>
    </>
  );
};

export default Document;
