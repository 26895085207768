import * as React from 'react';
import { Fragment } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';

interface MenuLink {
  text: string;
  path: string;
  method?: string;
}

interface Props {
  name: string;
  email: string;
  links: MenuLink[];
}

const AccountMenu: React.FC<Props> = (props) => {
  const { email, name } = props;

  return (
    <div className="ml-1 -mr-4 pl-2 flex items-center relative">
      <Menu>
        {({ open }) => (
          <div className="relative inline-block text-left">
            <div>
              <Menu.Button className="h-16 pr-6 pl-4 hover:bg-gray-50 text-gray-700 hover:text-black max-w-xs bg-white flex items-center focus:outline-none">
                <div className="inline-block flex-1 self-center pr-3 text-left">
                  {name}
                  <p className="text-sm text-gray-400">{email}</p>
                </div>
                {!open && <ChevronDownIcon className="h-6 w-6" />}
                {open && <ChevronUpIcon className="h-6 w-6" />}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-6 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1"></div>

                {props.links.map((link, i) => (
                  <Menu.Item key={i}>
                    {() => {
                      const attrs = {};

                      if (link.method) {
                        attrs['data-method'] = link.method;
                      }

                      return (
                        <a
                          href={link.path}
                          {...attrs}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          {link.text}
                        </a>
                      );
                    }}
                  </Menu.Item>
                ))}

                <div className="px-1 py-1"></div>
              </Menu.Items>
            </Transition>
          </div>
        )}
      </Menu>
    </div>
  );
};

export default AccountMenu;
