import * as React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

interface ErrorMessageProps {
  text: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ text }) => {
  return (
    <div className="bg-red-800 text-white p-5 mb-2 rounded">
      <div className="flex">
        <div className="flex">
          <ExclamationCircleIcon className="h-6 w-6" />
        </div>
        <div className="flex-1 ml-2">
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
