import * as React from 'react';
import { KeyDate } from '../../../../interfaces/models';
import { Entity } from '../EntityResults/Result';
import Table from '../../Table';
import { TrashIcon } from '@heroicons/react/24/outline';

interface RelatedKeyDatesProps {
  keyDates: KeyDate[];
  onRemove: (entity: Entity) => void;
}

const RelatedKeyDates: React.FC<RelatedKeyDatesProps> = ({
  keyDates,
  onRemove,
}) => {
  const onRemoveKeyDate = (
    e: React.MouseEvent<HTMLButtonElement>,
    keyDate: KeyDate
  ): void => {
    e.preventDefault();
    onRemove(keyDate);
  };

  if (keyDates.length > 0) {
    return (
      <>
        <h3 className="medium-title flex justify-between items-center">
          <span>Key Dates</span>
          <span>
            <a
              href={`${window.location.pathname}/notes/KeyDate`}
              className="btn"
            >
              Edit Notes &rarr;
            </a>
          </span>
        </h3>
        <Table headings={['ID', 'Date', 'Actions']}>
          {keyDates.map((keyDate) => (
            <tr
              key={`${keyDate.__typename}-${keyDate.id}`}
              className="hover:bg-gray-50"
            >
              <td className="w-1 px-6 py-2 whitespace-nowrap">{keyDate.id}</td>
              <td className="px-6 py-2 whitespace-nowrap">{keyDate.date}</td>
              <td className="w-2 px-6 py-2 whitespace-nowrap">
                <button
                  className="btn py-1"
                  onClick={(e) => onRemoveKeyDate(e, keyDate)}
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
        </Table>
      </>
    );
  } else {
    return null;
  }
};

export default RelatedKeyDates;
