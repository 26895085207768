import * as React from 'react';
import Result from './Result';
import { Article } from '../../../../interfaces/models';
import { EntityResultsBaseProps } from '../EntityResults';

interface ArticleResultProps extends EntityResultsBaseProps<Article> {
  result: Article;
}

const ArticleResult: React.FC<ArticleResultProps> = (props) => {
  const { id, title } = props.result;

  return (
    <Result
      title={title}
      subTitle={`Article: ${id}`}
      entity={props.result}
      onAddEntity={props.onAddEntity}
      onClearResults={props.onClearResults}
    />
  );
};

export default ArticleResult;
