import * as React from 'react';
import Result from './Result';
import { Video } from '../../../../interfaces/models';
import { EntityResultsBaseProps } from '../EntityResults';

interface VideoResultProps extends EntityResultsBaseProps<Video> {
  result: Video;
}

const VideoResult: React.FC<VideoResultProps> = (props) => {
  const { id, name } = props.result;

  return (
    <Result
      title={name}
      subTitle={`Video: ${id}`}
      entity={props.result}
      onAddEntity={props.onAddEntity}
      onClearResults={props.onClearResults}
    />
  );
};

export default VideoResult;
