import * as React from 'react';
import Document from './Document';

type LinkedDocument = {
  id: number;
  name: string;
  path: string;
  url: string;
  caption: string;
};

interface DocumentsProps {
  linkedDocuments: LinkedDocument[];
}

const Documents: React.FC<DocumentsProps> = ({ linkedDocuments }) => {
  return (
    <ul>
      {linkedDocuments.map((linkedDocument) => (
        <li key={linkedDocument.id}>
          <Document
            document={{
              name: linkedDocument.name,
              path: linkedDocument.path,
              caption: linkedDocument.caption,
            }}
            url={linkedDocument.url}
          />
        </li>
      ))}
    </ul>
  );
};

export default Documents;
