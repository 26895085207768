import * as React from 'react';
import Result from './Result';
import { KeyDate } from '../../../../interfaces/models';
import { EntityResultsBaseProps } from '../EntityResults';

interface KeyDateResultProps extends EntityResultsBaseProps<KeyDate> {
  result: KeyDate;
}

const KeywordResult: React.FC<KeyDateResultProps> = (props) => {
  const { id, date, notes } = props.result;

  return (
    <Result
      title={`${date} - ${notes}`}
      subTitle={`KeyDate: ${id}`}
      entity={props.result}
      onAddEntity={props.onAddEntity}
      onClearResults={props.onClearResults}
    />
  );
};

export default KeywordResult;
