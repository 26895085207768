import * as React from 'react';
import { useState } from 'react';
import Modal from '../Modal';

export type Image = {
  name: string;
  path: string;
  caption: string;
};

interface ImageProps {
  image: Image;
  url: string;
}

const Image: React.FC<ImageProps> = ({ image, url }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleImageClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();

    setIsOpen(true);
  };

  return (
    <>
      <a
        className="opacity-90 hover:opacity-100"
        onClick={handleImageClick}
        href={url}
      >
        <img
          className="inline-block rounded w-full"
          src={image.path}
          alt={image.name}
        />
      </a>

      <Modal title={image.name} open={isOpen} setOpen={setIsOpen}>
        <div className="space-y-4 text-center">
          <img
            className="block rounded modal-image mx-auto"
            src={image.path}
            alt={image.name}
          />
          <div
            className="px-4"
            dangerouslySetInnerHTML={{ __html: image.caption }}
          />
          <p className="px-4">
            <a className="block btn py-3" href={url}>
              More Details &rarr;
            </a>
          </p>
        </div>
      </Modal>
    </>
  );
};

export default Image;
