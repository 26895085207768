import * as React from 'react';
import { useState } from 'react';
import Modal from '../Modal';
import { VideoCameraIcon } from '@heroicons/react/24/outline';
import VideoPlayer from '../../Application/VideoPlayer';
import VimeoPlayer from '../../Application/VimeoPlayer';

export type Video = {
  name: string;
  path: string;
  downloadUrl: string;
  caption: string;
};

interface VideoProps {
  video: Video;
  url: string;
  vimeoId: string;
}

const Video: React.FC<VideoProps> = ({ video, url, vimeoId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleVideoClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();

    setIsOpen(true);
  };

  return (
    <>
      <a className="related-media-link" onClick={handleVideoClick} href={url}>
        <span className="pr-2">
          <VideoCameraIcon className="h-8 w-8" strokeWidth={1} />
        </span>
        <span className="flex-1">{video.name}</span>
        <span>
          <div className="pill">video</div>
        </span>
      </a>

      <Modal title={video.name} open={isOpen} setOpen={setIsOpen}>
        <div className="space-y-4 text-center">
          {!vimeoId && (
            <VideoPlayer url={video.path} downloadUrl={video.downloadUrl} />
          )}
          {vimeoId && <VimeoPlayer vimeoId={vimeoId} title={video.name} />}
          <div
            className="px-4"
            dangerouslySetInnerHTML={{ __html: video.caption }}
          />
          <p className="px-4">
            <a className="block btn py-3" href={url}>
              More Details &rarr;
            </a>
          </p>
        </div>
      </Modal>
    </>
  );
};

export default Video;
