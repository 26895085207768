import * as React from 'react';
import { useEffect, useState } from 'react';
import { fetchJSON } from '../../../../utilities';
import { Article } from '../../../../interfaces/models';
import EntitySelect from '../EntitySelect';
import Modal from '../../../Shared/Modal';
import Loading from '../../../Loading';
import EscapedLink, { escapedLinkForClipboard } from '../EscapedLink';
import Clipboard from '../../Clipboard';
import { LinkIcon } from '@heroicons/react/24/outline';

interface ArticleLookupProps {
  htmlMode: boolean;
}

const ArticleLookup: React.FC<ArticleLookupProps> = ({ htmlMode }) => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [selectedArticleId, setSelectedArticleId] = useState<string>();
  const [loadingArticle, setLoadingArticle] = useState(false);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [article, setArticle] = useState<Article>();
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setSelectedArticleId(e.target.value);
  };

  const handleArticleLookup = (
    e: React.MouseEvent<HTMLButtonElement>
  ): void => {
    e.preventDefault();

    if (!selectedArticleId) return;

    setLoadingArticle(true);

    fetchJSON(`/admin/published-articles/${selectedArticleId}.json`)
      .then((result) => {
        setArticle(result as Article);
        setIsOpen(true);
      })
      .catch((error) => {
        console.error('Article lookup error: ', error.message);
      })
      .finally(() => setLoadingArticle(false));
  };

  // load Articles dynmically on first load
  useEffect(() => {
    setLoadingArticles(true);

    fetchJSON(`/admin/published-articles.json`)
      .then((results) => {
        setArticles(results as Article[]);
      })
      .catch((error) => {
        console.error('Article load error: ', error.message);
      })
      .finally(() => setLoadingArticles(false));
  }, []);

  return (
    <>
      <h2 className="body-heading-title">
        Article Lookup
        {loadingArticle && (
          <Loading className="h-4 w-4 absolute top-1 right-0" />
        )}
      </h2>
      <p className="text-sm mb-4">
        Use this utility to lookup an Article and get a link to embed in your
        content.
      </p>

      {!loadingArticles && (
        <EntitySelect
          entities={articles}
          defaultText="-- Choose Article --"
          onSelectChange={handleSelectChange}
        />
      )}

      <button
        className="btn-primary w-full mt-4 ml-0 inline-flex items-center space-x-2"
        onClick={handleArticleLookup}
      >
        <LinkIcon className="h-6 w-6" />
        <span>Get Article Link</span>
      </button>

      {article && (
        <Modal title={article.title} open={isOpen} setOpen={setIsOpen}>
          <div className="flex">
            <div className="flex-auto w-3/5">
              <p className="text-sm">
                In order to link to the <strong>{article.title}</strong>{' '}
                article, copy the following{' '}
                {htmlMode && 'HTML and paste it into your Page.'}
                {!htmlMode &&
                  'URL and create a link using the text editor in your content.'}
              </p>
            </div>
            <div className="flex-auto text-right">
              {htmlMode && (
                <Clipboard
                  text={escapedLinkForClipboard(
                    `/entries/${article.id}`,
                    article.title
                  )}
                />
              )}
              {!htmlMode && <Clipboard text={`/entries/${article.id}`} />}
            </div>
          </div>

          <div className="mt-4 px-4 py-6 bg-gray-700 text-gray-100 text-sm overflow-x-auto whitespace-nowrap">
            {htmlMode && (
              <EscapedLink
                url={`/entries/${article.id}`}
                text={article.title}
              />
            )}
            {!htmlMode && `/entries/${article.id}`}
          </div>
        </Modal>
      )}
    </>
  );
};

export default ArticleLookup;
