import * as React from 'react';
import { Switch } from '@headlessui/react';

export enum LabelPosition {
  LEFT,
  RIGHT,
}

interface Props {
  label: string | React.ReactNode;
  labelPosition: LabelPosition;
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
}

const SwitchToggle: React.FC<Props> = ({
  label,
  labelPosition,
  enabled,
  setEnabled,
}) => {
  return (
    <div className="flex items-center">
      {labelPosition === LabelPosition.LEFT && (
        <div className="flex-1 pr-3 text-right text-gray-600">{label}</div>
      )}
      <div className="flex-none w-auto">
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={`${enabled ? 'bg-emerald-500' : 'bg-gray-200'}
            relative text-right inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500`}
        >
          <span
            aria-hidden="true"
            className={`${enabled ? 'translate-x-5' : 'translate-x-0'}
              pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
          />
        </Switch>
      </div>
      {labelPosition === LabelPosition.RIGHT && (
        <div className="flex-1 pl-3 text-left text-gray-600">{label}</div>
      )}
    </div>
  );
};

export default SwitchToggle;
