import * as React from 'react';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
} from '@heroicons/react/24/outline';

interface Props {
  text: string;
  buttonText?: string;
}

const Clipboard: React.FC<Props> = ({ text, buttonText }) => {
  const [copied, setCopied] = useState(false);

  const CopyIcon = copied ? ClipboardDocumentCheckIcon : ClipboardDocumentIcon;
  const defaultButtonText = buttonText ? buttonText : 'Copy';

  return (
    <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
      <button
        className={`relative inline-flex items-center space-x-2 px-4 py-2 border text-sm font-medium rounded-md focus:outline-none ${
          copied
            ? 'text-white  border-emerald-600 bg-emerald-500 hover:bg-emerald-600'
            : 'text-gray-700  border-gray-300 bg-gray-50 hover:bg-gray-100'
        }`}
      >
        <CopyIcon className="h-6 w-6" />
        <span>{copied ? 'Copied!' : defaultButtonText}</span>
      </button>
    </CopyToClipboard>
  );
};

export default Clipboard;
