import * as React from 'react';

interface Props {
  url: string;
  downloadUrl: string;
}

const VideoPlayer: React.FC<Props> = (props) => {
  const { url, downloadUrl } = props;

  return (
    <div className="video-player">
      <video controls width="100%">
        <source src={url} type="video/mp4" />

        <p>
          Your browser does not support HTML5 video, but you can still
          <a href={downloadUrl}>download the content</a>.
        </p>
      </video>
    </div>
  );
};

export default VideoPlayer;
