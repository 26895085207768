import * as React from 'react';
import Video from './Video';

type LinkedVideo = {
  id: number;
  name: string;
  path: string;
  url: string;
  downloadUrl: string;
  caption: string;
  vimeoId?: string;
};

interface VideosProps {
  linkedVideos: LinkedVideo[];
}

const Videos: React.FC<VideosProps> = ({ linkedVideos }) => {
  return (
    <ul>
      {linkedVideos.map((linkedVideo) => (
        <li key={linkedVideo.id}>
          <Video
            video={{
              name: linkedVideo.name,
              path: linkedVideo.path,
              downloadUrl: linkedVideo.downloadUrl,
              caption: linkedVideo.caption,
            }}
            url={linkedVideo.url}
            vimeoId={linkedVideo.vimeoId}
          />
        </li>
      ))}
    </ul>
  );
};

export default Videos;
