import * as React from 'react';

interface Props {
  url: string;
  downloadUrl: string;
}

const AudioPlayer: React.FC<Props> = (props) => {
  const { url, downloadUrl } = props;

  return (
    <div className="audio-player">
      <audio controls>
        <source src={url} type="audio/mpeg" />

        <p>
          Your browser does not support HTML5 audio, but you can still
          <a href={downloadUrl}>download the content</a>.
        </p>
      </audio>
    </div>
  );
};

export default AudioPlayer;
