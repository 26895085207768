import * as React from 'react';
import { useState } from 'react';
import { Answer } from '../../../interfaces/models';
import QuestionAnswer from './QuestionAnswer';

interface Props {
  answers: Answer[];
}

const QuestionAnswers: React.FC<Props> = (props) => {
  const [answers, setAnswers] = useState(props.answers);

  const handleAddAnswer = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    const newAnswer: Answer = {
      id: null,
      name: '',
      correct: false,
      __typename: 'Answer',
    };

    setAnswers([...answers, newAnswer]);
  };

  return (
    <>
      {answers.map((answer, i) => {
        return (
          <div key={i} className="grid grid-cols-9 gap-6 mb-2">
            <QuestionAnswer index={i} answer={answer} />
          </div>
        );
      })}

      <button className="btn mt-4" onClick={handleAddAnswer}>
        Add Answer
      </button>
    </>
  );
};

export default QuestionAnswers;
