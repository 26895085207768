import * as React from 'react';
import Result from './Result';
import { Audio } from '../../../../interfaces/models';
import { EntityResultsBaseProps } from '../EntityResults';

interface AudioResultProps extends EntityResultsBaseProps<Audio> {
  result: Audio;
}

const AudioResult: React.FC<AudioResultProps> = (props) => {
  const { id, name } = props.result;

  return (
    <Result
      title={name}
      subTitle={`Audio: ${id}`}
      entity={props.result}
      onAddEntity={props.onAddEntity}
      onClearResults={props.onClearResults}
    />
  );
};

export default AudioResult;
