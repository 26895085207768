import * as React from 'react';
import { useState } from 'react';
import Modal from '../../Shared/Modal';

const OpenModal: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    setOpen(true);
  };

  return (
    <>
      <button className="btn" onClick={handleOnClick}>
        Open Modal
      </button>
      <Modal title={'Check It Out'} open={open} setOpen={setOpen}>
        <p className="text-sm">This is my child body area</p>
      </Modal>
    </>
  );
};

export default OpenModal;
