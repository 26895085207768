import * as React from 'react';
import { TextEditorIconProps } from '../Icon';

const Heading1Icon: React.FC<TextEditorIconProps> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
    >
      <path fill="none" d="M0 0H24V24H0z" />
      <path d="M13 20h-2v-7H4v7H2V4h2v7h7V4h2v16zm8-12v12h-2v-9.796l-2 .536V8.67L19.5 8H21z" />
    </svg>
  );
};

export default Heading1Icon;
