import * as React from 'react';
import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { Collection } from '../../interfaces/models';
import CollectionInfo from './Collectable/CollectionInfo';
import NewCollection from './Collectable/NewCollection';
import SaveConfirmation from './Collectable/SaveConfirmation';
import { postJSON } from '../../utilities';

interface Collectable {
  id: number;
  type: string;
}

interface Props {
  collectable: Collectable;
  defaultCollections: Collection[];
}

const Collectable: React.FC<Props> = ({ collectable, defaultCollections }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [collections, setCollections] = useState(defaultCollections);
  const [isFormAvailable, setIsFormAvailable] = useState(false);
  const [isAddButtonAvailable, setIsAddButtonAvailable] = useState(true);
  const [selectedCollectionId, setSelectedCollectionId] = useState(
    defaultCollections.length ? defaultCollections[0].id : 0
  );

  const canSelectCollection = collections.length > 0;

  const buttonText = (): string => {
    const textOverride = {
      PublishedArticle: 'Article',
    }[collectable.type];

    return `Bookmark ${textOverride || collectable.type}`;
  };

  const handleAddToCollectionClick = (
    e: React.MouseEvent<HTMLAnchorElement>
  ): void => {
    e.preventDefault();
    setIsFormAvailable(true);
  };

  const handleCloseFormClick = (
    e: React.MouseEvent<HTMLAnchorElement>
  ): void => {
    e.preventDefault();
    setIsFormAvailable(false);
  };

  const handleNewCollectionCreate = (collection: Collection): void => {
    setCollections((currentCollections) => [...currentCollections, collection]);
    setSelectedCollectionId(collection.id);
  };

  const handleCollectionSelectChange = (
    e: React.FormEvent<HTMLSelectElement>
  ): void => {
    setSelectedCollectionId(Number(e.currentTarget.value));
  };

  const handleSaveCollectableClick = (
    e: React.MouseEvent<HTMLAnchorElement>
  ): void => {
    e.preventDefault();

    postJSON(`/collections/${selectedCollectionId}/bookmarks.json`, {
      collectable: {
        id: collectable.id,
        type: collectable.type,
      },
    })
      .then(() => {
        setIsConfirmed(true);
      })
      .catch((error) => {
        console.error('Error creating new Bookmark: ', error.message);
      });
  };

  return (
    <>
      <div className="collectable-wrapper">
        <Transition
          as={Fragment}
          show={isFormAvailable}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition ease-in duration-200"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
          beforeEnter={() => {
            setIsAddButtonAvailable(false);
          }}
          afterLeave={() => {
            setIsAddButtonAvailable(true);
          }}
        >
          <div className="collectable-form">
            <div className="p-6 pb-8 pr-4 pt-4 mb-10 bg-yellow-50 border-dashed border-2 border-yellow-300 rounded-lg">
              <div className="mb-1">
                <h3 className="flex items-center space-x-2 font-bold text-gray-700 text-base sm:text-xl">
                  <span>Add to Collection</span>
                  <span>
                    <CollectionInfo />
                  </span>
                  <span className="flex-1 text-right">
                    <a
                      href="/"
                      onClick={handleCloseFormClick}
                      className="inline-block collectable-link"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-8 h-8"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18 18 6M6 6l12 12"
                        />
                      </svg>
                    </a>
                  </span>
                </h3>
              </div>
              {canSelectCollection && (
                <div className="sm:flex items-center space-y-4 sm:space-y-0 sm:space-x-2">
                  <div className="sm:w-1/2">
                    <select
                      className="field-select !py-3"
                      placeholder="Choose an existing Collection"
                      value={selectedCollectionId}
                      onChange={handleCollectionSelectChange}
                    >
                      {collections.map((collection) => (
                        <option key={collection.id} value={collection.id}>
                          {collection.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="sm:space-x-4">
                    <a
                      href="/"
                      onClick={handleSaveCollectableClick}
                      className="btn"
                    >
                      Save Bookmark
                    </a>
                    <NewCollection
                      linkText="Create New Collection"
                      onCreate={handleNewCollectionCreate}
                    />
                  </div>
                </div>
              )}
              {!canSelectCollection && (
                <div className="space-y-4 sm:w-2/3">
                  <div>
                    Looks like you haven't created any Collections yet!{' '}
                    <CollectionInfo text="Click here" /> to learn more about
                    Collections and why we think they're so useful.
                  </div>
                  <div>
                    <strong>
                      <NewCollection
                        linkText="Create your first Collection"
                        onCreate={handleNewCollectionCreate}
                      />
                    </strong>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Transition>

        <Transition
          as={Fragment}
          show={isAddButtonAvailable}
          appear={true}
          enter="transition ease-out duration-50"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition ease-in duration-0"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
        >
          <div className="collectable-trigger">
            <a
              href="/"
              onClick={handleAddToCollectionClick}
              className="flex items-center space-x-2 btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                />
              </svg>
              <span>{buttonText()}</span>
            </a>
          </div>
        </Transition>

        <SaveConfirmation
          isOpen={isConfirmed}
          title="Bookmark Saved!"
          message="Collection updated! Remember, you can find all of your collections in your profile."
          onComplete={() => {
            setIsFormAvailable(false);
            setIsConfirmed(false);
          }}
        />
      </div>
    </>
  );
};

export default Collectable;
