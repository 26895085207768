import * as React from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/24/outline';

interface Props {
  title: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  children?: React.ReactNode;
}

const Modal: React.FC<Props> = ({ title, open, setOpen, children }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog onClose={setOpen}>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <div>
                  <div className="absolute top-4 right-4">
                    <button
                      onClick={() => setOpen(false)}
                      className="outline-none focus:outline-none"
                    >
                      <XCircleIcon
                        className="h-6 w-6 text-gray-300 hover:text-gray-700"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                  <div className="py-3 -mt-5 mb-4 -mx-6 px-6 border-b border-gray-200">
                    <Dialog.Title>
                      <span className="body-heading-title">{title}</span>
                    </Dialog.Title>
                  </div>
                </div>
                <div className="py-2">{children}</div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
