import * as React from 'react';
import { useState } from 'react';
import {
  closestCenter,
  DndContext,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import SortableItem from '../Shared/SortableItem';

interface Sortable {
  id: number;
  title: string;
}

interface SortableListProps {
  paramName: string;
  sortables: Sortable[];
}

interface SortableProps {
  index: number;
  paramName: string;
  sortable: Sortable;
}

const Sortable: React.FC<SortableProps> = ({ index, sortable, paramName }) => {
  return (
    <div className="flex justify-between rounded items-center p-4 border border-gray-200 hover:cursor-move">
      <div className="pr-4 text-gray-500">
        <span className="py-2 px-3 rounded-full bg-gray-900 text-white font-mono">
          {index}
        </span>
      </div>

      <div className="flex-1">{sortable.title}</div>

      <div className="p-4 -m-4 bg-gray-100 border-l border-gray-200">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1}
          stroke="currentColor"
          className="w-8 h-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
          />
        </svg>
      </div>

      <input
        type="hidden"
        name={`${paramName}[${sortable.id}]`}
        value={index}
        autoComplete="off"
      />
    </div>
  );
};

const SortableList: React.FC<SortableListProps> = ({
  paramName,
  sortables,
}) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const [items, setItems] = useState(sortables);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <div className="space-y-4">
          {items.map((sortable, index) => (
            <React.Fragment key={sortable.id}>
              <SortableItem key={sortable.id} sortableId={sortable.id}>
                <Sortable
                  index={index}
                  paramName={paramName}
                  sortable={sortable}
                />
              </SortableItem>
            </React.Fragment>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default SortableList;
