import * as React from 'react';
import { Fragment } from 'react';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';

interface MenuItems {
  text: string;
  path?: string;
}

interface Props {
  items: MenuItems[];
}

const AddEntityMenu: React.FC<Props> = (props) => {
  return (
    <div className="flex items-center pl-4 relative">
      <Menu>
        {({ open }) => (
          <div className="relative inline-block text-left">
            <div>
              <Menu.Button
                className={`p-2 text-sm rounded-full text-white focus:outline-none ${
                  open
                    ? 'bg-red-500 hover:bg-red-700'
                    : 'bg-emerald-500 hover:bg-emerald-700'
                }`}
              >
                {!open && <PlusIcon className="h-6 w-6 inline-block" />}
                {open && <XMarkIcon className="h-6 w-6 inline-block" />}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="popup-menu-position origin-top-right absolute mt-2 w-48 rounded-md shadow-lg py-1 pb-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1"></div>

                {props.items.map((item, i) => (
                  <Menu.Item key={i}>
                    {() => {
                      const isLink = item.path && item.path.length;

                      if (isLink) {
                        return (
                          <a
                            key={i}
                            href={item.path}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            {item.text}
                          </a>
                        );
                      } else {
                        return (
                          <h3
                            key={i}
                            className="uppercase px-3 py-1 text-gray-400 text-xs"
                          >
                            {item.text}
                          </h3>
                        );
                      }
                    }}
                  </Menu.Item>
                ))}

                <div className="px-1 py-1"></div>
              </Menu.Items>
            </Transition>
          </div>
        )}
      </Menu>
    </div>
  );
};

export default AddEntityMenu;
