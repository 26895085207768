import * as React from 'react';
import { Article } from '../../../../interfaces/models';
import { Entity } from '../EntityResults/Result';
import Table from '../../Table';
import { TrashIcon } from '@heroicons/react/24/outline';

interface RelatedArticlesProps {
  articles: Article[];
  onRemove: (entity: Entity) => void;
}

const RelatedArticles: React.FC<RelatedArticlesProps> = ({
  articles,
  onRemove,
}) => {
  const onRemoveArticle = (
    e: React.MouseEvent<HTMLButtonElement>,
    article: Article
  ): void => {
    e.preventDefault();
    onRemove(article);
  };

  if (articles.length > 0) {
    return (
      <>
        <h3 className="medium-title mb-2 mt-3">Articles</h3>
        <Table headings={['ID', 'Title', 'Actions']}>
          {articles.map((article) => (
            <tr
              key={`${article.__typename}-${article.id}`}
              className="hover:bg-gray-50"
            >
              <td className="w-1 px-6 py-2 whitespace-nowrap">{article.id}</td>
              <td className="px-6 py-2 whitespace-nowrap">{article.title}</td>
              <td className="w-2 px-6 py-2 whitespace-nowrap">
                <button
                  className="btn py-1"
                  onClick={(e) => onRemoveArticle(e, article)}
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
        </Table>
      </>
    );
  } else {
    return null;
  }
};

export default RelatedArticles;
