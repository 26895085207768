import * as React from 'react';
import { useState } from 'react';
import Modal from '../../Shared/Modal';

interface Props {
  text?: string;
}

const CollectionInfo: React.FC<Props> = ({ text }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleInfoClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();

    setIsOpen(true);
  };

  return (
    <>
      <a href="/" onClick={handleInfoClick} className="collectable-link">
        {text && <span>{text}</span>}
        {!text && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </a>
      <Modal title="What are Collections?" open={isOpen} setOpen={setIsOpen}>
        <div className="space-y-4">
          <p>
            Are you learning about a specific part of West Virginia? Or
            conducting a research project? Or just interested in lots of
            different things? Collections may be for you.
          </p>
          <p>
            e-WV has <em>a lot</em> of content... Collections allow you to
            bookmark related content (including Articles, Images, Documents,
            Videos, Authors, Quizzes, Photographers, and more) into a group all
            about a single topic. It could be a collection of your favorite
            articles, content about your hometown, audio clips you're interested
            in, or whatever you wish. They live in your Profile and can be
            accessed at any time.
          </p>
          <p>
            To get started, choose or create a collection, save the bookmark,
            and you're done!
          </p>
        </div>
      </Modal>
    </>
  );
};

export default CollectionInfo;
