import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
  PauseIcon,
} from '@heroicons/react/24/outline';
import { PlayIcon } from '@heroicons/react/24/solid';
import Ticker from './Ticker';

const INFINITE_LOOP_DELAY_MS = 10_000;

interface CarouselBaseProps {
  infinite: boolean;
  showTicker: boolean;
  summarizedTicker: boolean;
  width?: number;
}

const CarouselBase: React.FC<CarouselBaseProps> = ({
  children,
  infinite,
  showTicker,
  summarizedTicker,
  width,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
  const [length, setLength] = useState(React.Children.count(children));
  const [isPaused, setIsPaused] = useState(false);

  const carouselWidth = width || '100%';

  const nextItem = (): void => {
    if (currentIndex < length - 1) {
      setCurrentIndex((prevState) => prevState + 1);
      pause();
    }
  };

  const prevItem = (): void => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
      pause();
    }
  };

  const pause = (): void => {
    clearInterval(intervalId);
    setIsPaused(true);
  };

  const handleTickerClick = (tick: number): void => {
    setCurrentIndex(tick - 1);
    pause();
  };

  const handlePauseClick = (): void => {
    pause();
  };

  const handlePlayClick = (): void => {
    advanceSlide();
    setIntervalId(setInterval(advanceSlide, INFINITE_LOOP_DELAY_MS));
    setIsPaused(false);
  };

  const advanceSlide = useCallback((): void => {
    setCurrentIndex((prevState) =>
      prevState === length - 1 ? 0 : prevState + 1
    );
  }, [length]);

  useEffect(() => {
    let intervalId;

    if (infinite) {
      intervalId = setInterval(advanceSlide, INFINITE_LOOP_DELAY_MS);
      setIntervalId(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [advanceSlide, infinite]);

  useEffect(() => {
    setLength(React.Children.count(children));
  }, [children]);

  return (
    <div className="carousel-container" style={{ width: carouselWidth }}>
      <div className="carousel-wrapper">
        <div className="carousel-options">
          <div className="flex space-x-2">
            {showTicker && (
              <div>
                <Ticker
                  size={length}
                  currentIndex={currentIndex}
                  summarized={summarizedTicker}
                  onClick={handleTickerClick}
                />
              </div>
            )}
            <div>
              {!isPaused && (
                <button
                  className="carousel-play-pause"
                  onClick={handlePauseClick}
                >
                  <PauseIcon />
                </button>
              )}
              {isPaused && (
                <button
                  className="carousel-play-pause"
                  onClick={handlePlayClick}
                >
                  <PlayIcon />
                </button>
              )}
            </div>
          </div>
        </div>

        {currentIndex > 0 && (
          <button className="left-arrow" onClick={prevItem}>
            <ArrowSmallLeftIcon />
          </button>
        )}

        <div className="carousel-content-wrapper">
          <div
            className="carousel-content"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {children}
          </div>
        </div>

        {currentIndex < length - 1 && (
          <button className="right-arrow" onClick={nextItem}>
            <ArrowSmallRightIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default CarouselBase;
