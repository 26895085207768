import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useCodeMirror } from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import SwitchToggle, { LabelPosition } from '../../Shared/SwitchToggle';

interface Props {
  fieldName: string;
  value: string;
}

// Built based on react-codemirror:
// => https://www.npmjs.com/package/@uiw/react-codemirror

const CodeEditorField: React.FC<Props> = ({ fieldName, value }) => {
  const editor = useRef();
  const [pageHtml, setPageHtml] = useState(value);
  const [isDarkTheme, setIsDarkTheme] = useState(true);

  const handleOnChange = (newValue: string): void => {
    setPageHtml(newValue);
  };

  const { setContainer } = useCodeMirror({
    container: editor.current,
    extensions: [html()],
    value: pageHtml,
    theme: isDarkTheme ? 'dark' : 'light',
    onChange: handleOnChange,
    minHeight: '500px',
  });

  useEffect(() => {
    if (editor.current) {
      setContainer(editor.current);
    }
  }, [setContainer]);

  return (
    <div className="body-section">
      <div className="flex">
        <div className="flex-1">
          <label className="label">Custom HTML</label>
        </div>
        <div className="flex-1 text-right">
          <SwitchToggle
            label="Dark Theme"
            labelPosition={LabelPosition.LEFT}
            enabled={isDarkTheme}
            setEnabled={setIsDarkTheme}
          />
        </div>
      </div>
      <div className="hidden">
        <textarea name={fieldName} value={pageHtml} readOnly={true} />
      </div>
      <div className="code-editor" ref={editor} />
    </div>
  );
};

export default CodeEditorField;
