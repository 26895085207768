import * as React from 'react';
import Result from './Result';
import { Image } from '../../../../interfaces/models';
import { EntityResultsBaseProps } from '../EntityResults';

interface ImageResultProps extends EntityResultsBaseProps<Image> {
  result: Image;
}

const ImageResult: React.FC<ImageResultProps> = (props) => {
  const { id, name } = props.result;

  return (
    <Result
      title={name}
      subTitle={`Image: ${id}`}
      entity={props.result}
      onAddEntity={props.onAddEntity}
      onClearResults={props.onClearResults}
    />
  );
};

export default ImageResult;
