import * as React from 'react';
import {
  Article,
  Audio,
  Document,
  Image,
  Page,
  Video,
} from '../../../interfaces/models';

interface EntitySelectProps {
  entities: (Article | Audio | Document | Image | Video)[];
  defaultText: string;
  onSelectChange: (value: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface EntitySelectOptionProps {
  text: string;
  value: number;
}

const EntitySelectOption: React.FC<EntitySelectOptionProps> = (props) => {
  const { text, value } = props;

  return <option value={value}>{text}</option>;
};

const EntitySelect: React.FC<EntitySelectProps> = (props) => {
  const { entities, defaultText, onSelectChange } = props;

  const optionText = (
    entity: Article | Audio | Document | Image | Video
  ): string => {
    switch (entity.__typename) {
      case 'Article':
        return (entity as Article).title;
      case 'Audio':
        return (entity as Audio).name;
      case 'Document':
        return (entity as Document).name;
      case 'Image':
        return (entity as Image).name;
      case 'Page':
        return (entity as Page).title;
      case 'PublishedArticle':
        return (entity as Article).title;
      case 'Video':
        return (entity as Video).name;
    }
  };

  return (
    <select className="field-select" onChange={onSelectChange}>
      <EntitySelectOption key={0} text={defaultText} value={0} />

      {entities.map((entity, index) => (
        <EntitySelectOption
          key={index + 1}
          text={optionText(entity)}
          value={entity.id}
        />
      ))}
    </select>
  );
};

export default EntitySelect;
